<!--
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-04-01 17:11:04
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2021-04-06 12:30:01
-->
<template>
  <div class="goodsList">
    <search @add="addGoods"></search>
    <div class="tableBox">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        row-class-name="rowClassName"
        :highlight-current-row="true"
      >
        <el-table-column prop="date" label="商品名称" width="180">
        </el-table-column>
        <el-table-column prop="name" label="上架时间" width="180">
        </el-table-column>
        <el-table-column prop="price" label="商品价格" width="180"> </el-table-column>
        <el-table-column prop="discount" label="商品折扣" width="180"> </el-table-column>
        <el-table-column prop="label" label="商品标签"> </el-table-column>
        <el-table-column prop="desc" label="商品描述"> </el-table-column>
        <el-table-column label="操作" width="180">
          <el-button type="text">查看</el-button>
          <el-button type="text">删除</el-button>
        </el-table-column>
      </el-table>
      <div class="pageBox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="pageTotal"
          background
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
    <adddialog ref="addDialog"></adddialog>
  </div>
</template>

<script>
import search from "./com/search.vue";
import adddialog from "./com/addDialog"
export default {
  components: { search,adddialog },
  props: {},
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      currentPage: 1,
      pageSize: 15,
      pageTotal: 500,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleCurrentChange() {},
    addGoods(){
        this.$refs.addDialog.showDialog();
    }
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.goodsList {
  .tableBox {
    margin-top: 20px;
    color: #000;
  }
  .pageBox{
      text-align: right;
      padding: 10px;
      background: #fff;
  }
  .rowClassName{
    height: 40px;
  }
}
</style>