<!--
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-04-01 16:33:37
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2021-04-01 17:46:03
-->
<template>
  <div class="goodsSearch">
    <el-form>
      <el-form-item label="商品加个：">
        <el-input
          v-model="form.price"
          style="width: 120px"
          size="small"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品类型：">
        <el-input
          v-model="form.input"
          style="width: 120px"
          size="small"
          placeholder="请输入内容"
        ></el-input>
        <el-button
          size="small"
          class="goodsSearchBtn"
          type="primary"
          @click="researchData()"
          >查询</el-button>
          <el-button
          size="small"
          class="goodsSearchBtn"
          type="primary"
          @click="addGood()"
          >新增</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      form:{
        price:"",
        input: ""
      }
      
    };
  },
  watch: {},
  computed: {},
  methods: {
    researchData() {},
    addGood(){
      this.$emit("add")
    }
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.goodsSearch ::v-deep {
  background: #fff;
  clear: both;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 10px 0px;
  .el-radio-button__inner {
    border: none;
    width: 88px;
    padding: 10px 10px;
  }
  .el-form-item {
    display: inline-block;
    margin: 0;
    margin-left: 20px;
    .el-form-item__content{
      display: inline-block;
    }
  }
  .goodsSearchBtn{
    width: 80px;
    margin-left: 20px;
  }
}
</style>